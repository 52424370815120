.breadcrumbs {
    display: inline-block;
    
    a {
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        display: inline-block;
        background: $theme-gray;
        border-radius: 1px;
        color: #fff;
        margin: 0 2px 4px 0;
        padding: .2rem .8rem;

        &:nth-last-child(1){
            border-right: none;
        }
    }
}