.card {
    border: none;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.15);

    &__img {
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 2px 10px -8px rgba(0, 0, 0, 0.8);
    }

    &__header{
        font-weight: 700;
        margin-bottom: 2rem;

        h5 {
            margin-bottom: 0;
        }

        span {
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            color: $theme-gray;
        }
    }

    &__body {
        padding: 2rem 1.5rem 1rem;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .prize {
            font-size: 2rem;
            font-weight: 700;

            span {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}