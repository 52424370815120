.sidebar {
    &__header {
        text-align: center;
        padding: 1rem 1rem .3rem;
        background: $theme-dark;
        color: #fff;
    }

    .categories {
        background: #fff;
        color: $theme-gray;
        box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .15);


        ul {
            list-style: none;
            padding: 0;

            li {
                font-weight: 700;
                color: $theme-gray;
                padding: 1rem;
                transition: all .2s cubic-bezier(0.47, 0, 0.745, 0.715);

                a {
                    display: block;
                    color: inherit;
                }

                @include hover-focus-active(){
                    background: rgba($theme-gray, .15);
                }
            }
        }
    }

    .searchform {
        box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .15);
        form {
            box-shadow: none;
            padding-bottom: 1.5rem;

            input[type=text] {
                border: none;
                border-bottom: 1px solid $theme-dark;
                margin-right: 20px;
            }
            input[type=submit] {
                @extend .shop-button;

                border: none;
                line-height: 12px;
            }
        }
    }

    .tags {
        box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .15);

        &__body {
            padding: 1rem 1rem .5rem;
        }

        .tag {
            display: inline-block;
            background: $theme-gray;
            padding: .4rem 1rem;
            border-radius: 20px;
            margin-right: .5rem;
            margin-bottom: .5rem;
            transition: all .3s ease;

            a {
                font-weight: 500;
                color: #fff;
            }

            &:hover {
                background: #000;
            }
        }
    }
}