.btn {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    display: inline-block;
    padding: .45rem 1.2rem;
    text-decoration: none;
    transition: all .3s cubic-bezier(0.645, 0.245, 0.355, 1);
    z-index: 9;

    &:hover {
        text-decoration: none;
    }
    
    &::before {
        content: '';
        position: absolute;
        right: 0;
        left: -17px;
        bottom: 0;
        width: 125%;
        height: 100px;
        border-radius: 100%;
        transform: translateY(-50px);
        transition: transform .4s cubic-bezier(0.645, 0.245, 0.355, 1);
        z-index: -1;
    }

    &--gray {
        color: #fff;
        background: $theme-gray;
        border: 2px solid $theme-gray;

        &::before {
            background: #fff;
        }
        &:hover {
            color: $theme-gray;
        }
    }
    
    &--black {
        color: #fff;
        background: $theme-dark;
        border: 2px solid $theme-dark;

        &::before {
            background: #fff;
        }
        &:hover {
            color: $theme-dark;
        }
    }

    &--white {
        color: $theme-dark;
        background: #fff;
        border: 2px solid #fff;
        
        &::before {
            background: $theme-dark;
        }
        &:hover {
            color: #fff;
        }
    }

    &--contrast {
        color: #fff;
        background: $theme-contrast;
        border: 2px solid $theme-contrast;
        
        &::before {
            background: #fff;
        }
        &:hover {
            color: $theme-contrast;
        }
    }

    &--cart {
        font-size: 12px !important;
        color: #fff;
        background: $theme-gray;
        border: 2px solid $theme-gray;

        span {
            position: relative;
            top: 1px;
            left: 3px;
        }

        &:hover {
            color: #fff !important;
        }
    }

    &:hover {
        &::before {
            transform: translateY(30px);
        }
    }
}

// Special button class to extend
.shop-button {
    font-weight: 500 !important;
    border-radius: 30px !important;
    color: #fff !important;
    background: $theme-gray !important;
    padding: .8rem 1rem !important;
    max-height: 45px;
}
