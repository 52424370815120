.woocommerce-order {
    .order_details {
        li {
            margin-bottom: 1rem;
        }
    }

}

.woocommerce-customer-details {
    address {
        border: none !important;
    }
}