.woocommerce-MyAccount-navigation {
    ul {
        list-style: none;
        padding: 0;

        li {
            a {
                display: block;
                padding: 1rem;
                color: #fff;
                background: $theme-gray;
            }

            &.is-active {
                a {
                    background: $theme-dark;
                    color: #fff;
                }
            }
        }
    }
}

.woocommerce-MyAccount-content {
    a {
        color: $theme-contrast;
    }

    p {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: .5rem;
    }

    form {
        .form-row {
            border-bottom: none !important;
            span {
                width: 100%;
                input {
                    &.input-text {
                        border-radius: 2px;
                        padding: .4rem .5rem;
                        border: 1px solid $theme-gray !important; 
                    }
                }
                &.show-password-input {
                    width: auto;
                    top: .4em !important;
                }
            }
        }

        &.edit-account {
            input {
                border-radius: 2px;
                padding: .4rem .5rem;
                border: 1px solid $theme-gray !important; 
            }
        }
    }

    button {
        @extend .shop-button;
    }
    
    input[type=checkbox] {
        @extend .shop-input;
    }

    .select2-selection {
        border-radius: 2px !important;
        padding: .4rem .5rem;
        border: 1px solid $theme-gray !important; 
        height: 40px !important;
    }

    .woocommerce-Address {
        width: 100%;
        max-width: 100%;
    }
}