.post {
    margin: 1rem;
    background: #fff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.15);
    
    &__date {
        font-family: $font-prime;
        font-size: 14px;
        color: $theme-gray;
        margin-bottom: .5rem;
    }
    
    &__category,
    &__tags {
        font-size: 14px;
        a {
            color: $theme-gray;
        }
    }
    
    &__body {
        padding: 2rem 1.5rem;
    }

    &__img {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &__thumbnail {
        position: relative;
        width: 100%;
        height: 350px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}