html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-second;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-prime;
  font-weight: 600;
}

h1 {
  font-size: 2rem;

  @include media-breakpoint-down(sm){
    font-size: 1.5rem;
  }
}

a,
a:hover {
  text-decoration: none;
}

.page-template-default {
  a {
    color: $theme-gray;
  }
}

textarea {
  height:175px !important;
}

.hr {
  &--small {
    border-top: 3px solid #000;
    width: 100px;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
  &--white {
    border-top: 3px solid #fff;
  }
}

#offer {
  scroll-behavior: smooth;
}
