.navbar {
    background: #fff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 9;
  
    .navbar-nav {
      @include media-breakpoint-up(xl){
        align-items: center;
      }
    }
}
  
.navbar-toggler {
  font-size: 1.1rem;
  background: $theme-dark;
  border-radius: 30px;
  padding: .6rem 1rem;
  border: none;

  img {
    width: 20px;
  }
}

.navbar-brand {
    font-family: $font-prime;
    font-size: 18px;
    font-weight: 700;
    color: $theme-dark;

    img {
      max-width: 60px;
    }
}

.nav-item {
  .nav-link {
    font-size: 14px;
    text-transform: uppercase;
    color: $theme-dark !important;
    line-height: 35px;
    transition: all .25 ease;
    
    @include media-breakpoint-down(lg){
      line-height: 30px;
    }
  }
  .btn {
    font-size: 14px;
  }
  &.show {
    font-weight: 700;
  }
}
  
.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #fff;
  border:0px;
  padding: 1rem 0;
  
  @include media-breakpoint-down(lg){
    padding: 0;
  }

  .dropdown-item {
    color: $theme-gray;
    transition: all .3s ease-in-out;
    
    &:hover {
      color: $theme-dark;
      background-color:transparent;
    }
  }
}

.nav-hr {
  position: relative;
  width: 100%;
  height: 1px;
  background: #acb1b7;
  margin: 1rem auto;
  
  @include media-breakpoint-up(xl){
    width: 1px;
    height: 35px;
    margin: auto 1rem;
  }
}

.search-box {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 60px;
  background: #fff;
  width: 100%;
  max-width: 650px;
  border-radius: 0 0 3px 3px;
  transform: translateY(-60px);
  transition: transform .3s ease;
  z-index: 1;
  
  &.open {
    transform: translateY(0);
  }
}
  