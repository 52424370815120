.woocommerce-cart {
    .shop_table {

        td {
            padding: 12px !important;
            border-top: none !important;

            &.product-remove {
                a.remove {
                    color: $theme-red !important;
                    transition: all .2s ease;

                    &:hover {
                        color: #fff !important;
                        background: $theme-red !important;
                    }
                }
            }
            &.product-name {
                a {
                    font-weight: 500 !important;
                    color: $theme-contrast;
                }
            }
            &.product-quantity {
                .quantity {
                    input {
                        border-radius: 2px;
                        padding: .2rem .5rem;
                        border: 1px solid $theme-gray !important; 
                    }
                }
            }
            &.actions {
                .coupon {
                    input {
                        border-radius: 2px;
                        padding: .2rem .5rem;
                        border: 1px solid $theme-gray !important;
                        margin: 0 .5rem 0 0 !important;
                        min-width: 130px;
                    }
                    button {
                        @extend .shop-button;
                    }
                }

                button[type=submit] {
                    @extend .shop-button;
                }
            }
        }

    }

    .cart_totals {
        h2 {
            margin-bottom: 1rem;
        }

        table {
            border-radius: 0 !important;

            tr {
                &.woocommerce-shipping-totals {
                    td {
                        .woocommerce-shipping-calculator {
                            a {
                                color: $theme-contrast !important;
                            }
                            span {
                                &.select2-selection {
                                    border-radius: 2px;
                                    padding: .4rem .5rem;
                                    border: 1px solid $theme-gray !important; 
                                    height: 36px !important;
                                    span {
                                        padding-left: 0 !important;
                                    }
                                }
                            }
                            input {
                                border-radius: 2px;
                                padding: .4rem .5rem;
                                border: 1px solid $theme-gray !important; 
                            }
                            button {
                                @extend .shop-button;
                            } 
                        }
                    }
                }
                &.order-total {
                    td {
                        color: $theme-contrast !important;
                    }
                }
            }
        }

        .checkout-button {
            background-color: $theme-contrast !important;
            border-radius: 50px;
        }
    }
}