.shop_table {
    @extend .table;
    @extend .table-striped;
    @extend .table-borderless;

    border: none !important;
    border-collapse: collapse !important;

    thead {
        background: $theme-dark;
        color: #fff;
    }

    td {
        a {
            color: $theme-contrast !important;
        }
    }
}