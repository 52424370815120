// Pagiantion
ul.page-numbers{
    list-style: none;
    margin-top: 1.5rem;

    li {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;

        a {
            color: inherit!important;
        }

        & > span.current{
            display: block;
            color: #fff;
            background: #000;
        }
    }
}

.shop-input {
    position: relative;
    -webkit-appearance: none;
    background: none;
    border: 2px solid $theme-dark;
    color: $theme-dark;
    text-transform: uppercase;
    font-weight: 400;
    transition: all 0.5s ease-in;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: -7px;
    transition: all .2s ease;

    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        font-size: 1rem;
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background: url('../img/checked.svg') no-repeat center center;
        z-index: 99;
        opacity: 0;
        transform: opacity .2s ease;
    }

    &:checked {
        background: #000;
        &::after {
            opacity: 1;
        }
    }
}

// Ajax search 

.dgwt-wcas-search-form {
    .dgwt-wcas-search-input {
        border: none !important;
        border-bottom: 1px solid #ddd !important;
    }
}
