.woocommerce-form-login {
    max-width: 600px;
    margin: auto !important;
    box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .15);
    border: none !important;
    border-radius: 0 !important;

    span {
        width: 100%;

        &.show-password-input {
            width: auto;
        }
    }

    input {
        &.input-text {
            @extend .form-control;
    
            border-radius: 1px;
            padding: .3rem .5rem;
        }
        &.woocommerce-form__input-checkbox {
            @extend .shop-input;
        }
    }

    button {
        @extend .shop-button;

        margin: auto !important;
    }

    .woocommerce-form-login__rememberme {
        width: 100%;
    }

    .lost_password {
        font-size: 14px;
        text-align: center;
        margin-top: 1rem;

        a {
            color: $theme-contrast !important;
        }
    }
}

.woocommerce-lost-password {
    input {
        border-radius: 2px;
        padding: .4rem .5rem;
        border: 1px solid $theme-gray !important; 
    }

    button {
        @extend .shop-button;
    }
}