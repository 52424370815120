.hero {
  position: relative;
  height:600px;
  
  &__slide {
    height:600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    h1 {
      color: #fff;
      font-weight: 700;
    }

    img {
      max-width: 300px;
    }
  }

}
