.woocommerce {

    &-info {
        border-top-color: $theme-contrast !important;

        &:before {
            color: $theme-contrast !important;
        }
    }

    // Products List
    .products {

        .product {
            box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .15) !important;

            @include media-breakpoint-down(xs){
                width: 100% !important;
            }

            img {
                box-shadow: 0 2px 10px -8px rgba(0,0,0,.8) !important;
            }
            .onsale {
                min-height: 30px;
                padding: .6rem .8rem;
                border-radius: 0;
                line-height: 160%;
                margin: 0 !important;
                background-color: $theme-contrast;
            }

            // Product title
            .woocommerce-loop-product__title {
                font-size: 1.25rem !important;
                padding: 1rem 1.5rem 0 !important;
                color: #000;
            }

            // Price with sale
            &.sale {
                .price {
                    color: $theme-contrast !important;

                    del {
                        font-size: 1rem;
                    }
                    
                    ins {
                        font-size: 1.4rem;
                        font-weight: 700;
                        text-decoration: none !important;
                    }
                }
            }
            
            // Price without sale
            .price {
                font-size: 1.4rem !important;
                font-weight: 700 !important;
                color: $theme-contrast !important;
                padding: 0 1.5rem 0;
            }     

            // Button "Add to cart"
            .add_to_cart_button {
                @extend .btn;

                color: #fff;
                width: 100%;
                border-radius: 0;
                background: $theme-gray;
                border: none;
                
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    background: $theme-gray;
                    color: #fff;
                }
                
                &.added {
                    border: 2px solid $theme-contrast;
                    background: $theme-contrast;
                    &::before {
                        background: $theme-contrast;
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }

            // Notification "Added to cart"
            .added_to_cart {
                display: block;
                padding: .45rem 1.2rem;
                background: $theme-gray;
                color: #fff;
                text-align: center;
            }
        }
    }

    .single-product {
        .product {
            .onsale {
                top: 0;
                left: 0;
                padding: .6rem .8rem;
                border-radius: 0;
                background-color: $theme-contrast;
                line-height: 120%;
                min-height: 30px !important;
            }

            // Price with sale
            &.sale {
                .price {
                    color: $theme-contrast !important;

                    del {
                        font-size: 1.2rem;
                    }
                    
                    ins {
                        font-size: 2rem;
                        font-weight: 700;
                        text-decoration: none !important;
                    }
                }
            }
            
            // Price without sale
            .price {
                font-size: 2rem !important;
                font-weight: 700 !important;
                color: $theme-contrast !important;
                
                @include media-breakpoint-down(md){
                    font-size: 1.5rem !important;
                }
            }  

            .quantity {
                margin: 4px 10px 0 0 !important;

                input {
                    border: 2px solid $theme-gray;
                    padding: 4px 10px;
                }
            }

            .single_add_to_cart_button {
                @extend .btn;

                color: #fff;
                border-radius: 30px;
                background: $theme-gray;
                border: 2px solid $theme-gray;
                
                &::before {
                    background: #fff;
                }
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    color: $theme-gray;
                    background-color: transparent !important;
                }
            }

            .product_meta {
                span {
                    a {
                        color: $theme-gray;
                    }
                }
            }

            // Product tabs
            .woocommerce-tabs {
                .tabs {
                    padding: 0;

                    &::before {
                        border-bottom: 1px solid $theme-dark;
                    }

                    li {
                        background: $theme-gray;                        
                        border: none;
                        border-radius: 0 !important;
                        
                        a {
                            font-weight: 500 !important;
                            color: #fff !important;
                        }

                        &::before,
                        &::after {
                            display: none;
                        }

                        &.active {
                            color: #fff;
                            background: $theme-dark;
                        }
                    }
                }
            }

            .woocommerce-product-attributes {
                td {
                    a {
                        color: $theme-contrast !important;
                    }
                }
            }
        }

    }

    .woocommerce-form-coupon {
        input {
            border-radius: 2px;
            padding: .4rem .5rem;
            border: 1px solid $theme-gray !important; 
        }

        button {
            @extend .shop-button;
        }
    }

    .wc-backward {
        @extend .shop-button;
    }

    .order_details {
        padding: 0 !important;
    }
}

#payment {
    form {
        a.cancel {
            @extend .shop-button
        }
        input[type=submit] {
            background-color: $theme-contrast !important;
            border-radius: 40px !important;
        }
        p {
            margin-top: 1rem;
        }
    }    
}