.checkout {
    .woocommerce-billing-fields,
    .woocommerce-shipping-fields {
        .form-row {
            width: 100%;

            label {
                margin-bottom: 0;
            }

            &.woocommerce-invalid {
                label {
                    color: $theme-red !important;
                }
            }

            span {
                width: 100%;

                input {
                    border-radius: 2px;
                    padding: .4rem .5rem;
                    border: 1px solid $theme-gray !important; 
                }
            }

            .select2-selection {
                border-radius: 2px;
                padding: .4rem .5rem;
                border: 1px solid $theme-gray !important; 
                height: 40px !important;
            }

        }
        
        #ship-to-different-address {
            margin-bottom: 0;
        }

        input[type=checkbox] {
            @extend .shop-input;

            top: -5px;
        }
    }

    .woocommerce-additional-fields {
        .notes {
            span {
                width: 100%;
                textarea {
                    padding: .5rem;
                }
            }
        }
    }

    .woocommerce-checkout-payment {
        background: $theme-gray;
        border-radius: 0 !important;
        color: $theme-dark;

        a {
            color: $theme-contrast;
        }

        button {
            background-color: $theme-contrast !important;
            border-radius: 40px !important;
        }
    }

}
