.filters__btn {
    background: $theme-dark;
    padding: .5rem 1rem;
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg){
        display: none;
    }
}

.filters {
    padding: 0 15px;
    height: 0;
    overflow: hidden;
    transition: height .3s ease-in-out;

    &.show {
        height: auto;
    }

    @include media-breakpoint-up(lg){
        height: auto;
    }
}

.woof_container {
    box-shadow: 2px 5px 15px rgba($color: #000000, $alpha: .15);
    margin-bottom: 1.5rem !important;

    .woof_container_inner {
        h4 {
            @extend .sidebar__header;
        }

        ul {
            padding-left: 20px;

            li {
                label {
                    margin-top: 4px;
                    margin-bottom: 0;
                }

                input[type="checkbox"] {
                   @extend .shop-input;
                }
            }
        }

        .price_slider_wrapper {
            padding: 1rem;
            .price_slider {
                .ui-slider-range {
                    background-color: $theme-gray;
                }
                .ui-slider-handle {
                    background-color: $theme-dark;
                }
            }
        }
    }

    &.woof_container_select {
        select {
            margin: 0.5rem 1rem;
            width: calc(100% - 2rem);
            padding: .5rem;
        }
    }

    .woof_list_checkbox {
        .woof_childs_list_li {
            .woof_childs_list_opener {
                vertical-align: bottom !important;
                span {
                    &.woof_is_closed {
                        background: url('./../img/chevron-down.svg') no-repeat center !important;
                    }
                    &.woof_is_opened {
                        background: url('./../img/chevron-down.svg') no-repeat center !important;
                        transform: rotate(180deg);
                    }
                }
            }
            .woof_childs_list {
                padding-left: 0 !important;
                margin: 7px 0 0 8px !important;

                li {
                    label {
                        font-size: 14px;
                        color: $theme-gray;
                    }
                }
            }
        }
    }
}

.woof_submit_search_form_container {
    .button {
        display: block !important;
        font-weight: 500 !important;
        background-color: $theme-gray !important;
        color: #fff !important;
        border-radius: 0 !important;
        width: 100%;
        margin-bottom: .5rem !important;
        
        &.woof_submit_search_form {
            background-color: $theme-contrast !important;
        }
    }
}

.woof_products_top_panel {
    ul {
        li {
            a {
                color: $theme-dark;
                
                span {
                    padding-right: 22px;
                    background-image: url('../img/delete.svg') !important;
                    background-size: 20px 20px;
                    background-repeat: no-repeat;
                    background-position: right;
                    
                    &:hover {
                        background-size: 20px 20px;
                    }
                }
            }
        }
    }
}