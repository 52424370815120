.woocommerce-message {
    border-top-color: $theme-green !important;
    background-color: $theme-green !important;
    line-height: 38px;
    color: #fff !important;

    &::before {
        color: #fff !important;
    }

    .button {
        font-weight: 500 !important;
        border-radius: 40px !important;
        color: $theme-dark !important;
        background: #fff !important;
    }
}

.woocommerce-error {
    border-top-color: $theme-red !important;
    background-color: $theme-red !important;
    color: #fff !important;

    &::before {
        color: #fff !important;
    }
}

.woocommerce-info {
    border-top-color: $theme-contrast !important;
    background-color: $theme-contrast !important;
    color: #fff !important;

    &::before {
        color: #fff !important;
    }

    a {
        color: #fff;
    }

    .button {
        font-weight: 500 !important;
        border-radius: 40px !important;
        color: $theme-dark !important;
        background: #fff !important;
    }
}

.woocommerce-notice {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
}