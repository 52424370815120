.showcase {
  &--text {
    padding: 3rem;

    @include media-breakpoint-up(md){
      padding: 7rem 5rem !important;
    }
  }
  &--img {
    min-height: 20rem;
    background-size: cover;
    background-position: center;
    background-color: #c7c7c7;
    background-blend-mode: multiply;
    
    @include media-breakpoint-up(md){
      min-height: 30rem;
    }
  }
}
